/* Colors */

$pink : #FA8BFF;
$green: #2BFF88;
$blue  : #2BD2FF;
$black  : #111111;
$white : #FFF   ;

/* fonts */

@font-face {
    font-family: GarciaMarquez;
    src: url('../fonts/GarciaMarquez.otf');
}
@font-face {
    font-family: Karmilla-Bold;
    src: url('../fonts/Karmilla-Bold.ttf');
}
@font-face {
    font-family: Karmilla-Regular;
    src: url('../fonts/Karmilla-Regular.ttf');
}

h1,h3,h4,h5,h6{
    font-family: GarciaMarquez;
}
h1{
    font-size: 6rem;
}
p{
    font-family: Karmilla-Regular;
    font-size: 1.5rem;
}

/* flex-box */

%flex-row-center{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

%flex-center-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* general settings */
*::selection{
    background-color: $pink;
    color: $white;
}
html{
    scroll-behavior: smooth;
    scroll-padding-top: 142px;
}
body{
    overflow-x: hidden;
}
body{
    background-color: #f8f8f8;
}
.container{
    padding: 0 5%;
}
.button-blue{
    font-family: Karmilla-Regular;
    color: $black;
    background-color: $blue;
    border: 2px solid $black;
    border-radius: 100px;
    box-shadow: 2px 4px 0 0 $black;
    width: 240px;
    height: 40px;
    transition: 0.3s;
    @extend %flex-center-center;
    &:hover{
        color: $blue;
        background-color: $black;
        border: 2px solid $blue;
        border-radius: 100px;
        box-shadow: 2px 4px 0 0 $blue;
        transition: 0.3s;
    }
}
.button-green{
    font-family: Karmilla-Regular;
    color: $black;
    background-color: $green;
    border: 2px solid $black;
    border-radius: 100px;
    box-shadow: 2px 4px 0 0 $black;
    width: 240px;
    height: 40px;
    transition: 0.3s;
    @extend %flex-center-center;
    &:hover{
        color: $green;
        background-color: $black;
        border: 2px solid $green;
        border-radius: 100px;
        box-shadow: 2px 4px 0 0 $green;
        transition: 0.3s;
    }
}
.button-pink{
    font-family: Karmilla-Regular;
    color: $black;
    background-color: $pink;
    border: 2px solid $black;
    border-radius: 100px;
    box-shadow: 2px 4px 0 0 $black;
    width: 240px;
    height: 40px;
    transition: 0.3s;
    @extend %flex-center-center;
    &:hover{
        color: $pink;
        background-color: $black;
        border: 2px solid $pink;
        border-radius: 100px;
        box-shadow: 2px 4px 0 0 $pink;
        transition: 0.3s;
    }
}

@media screen and (max-width: 1280px) {
    *{
        transition: 0.3s;
    }
    h1{
        font-size: 5rem!important;
    }
    .container{
        padding: 0 10%;
    }
    .dark-section{
        padding: 5% 10%!important;
    }
    .question{
        margin: 5% 10%!important;
        p{
            font-size: 2.5rem!important;
        }
    }
    .footer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 1.5rem;
        padding: 5% 10%!important;
        ul{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: fit-content;
            a{
                margin: 2% 0;
                &:hover{
                    box-shadow: inset 0 calc(-1.5rem - 10px) 0 0 $white;
                }
            }
        }
    }
    .ecole{
        .first{
            width: 35vw!important;
        }
        .second{
            width: 40vw!important;
        }
    }
}
@media screen and (min-width:1025px){
    .menu-mobile-expanded{
        display: none!important;
    }
}
@media screen and (max-width: 1024px) {

    h1{
        font-size: 4rem!important;
    }
    .navigation{
        .menu{
            display: none!important;
        }
        button{
            display: block!important;
        }
    }
    
}
@media screen and (max-width:768px){
    .navigation{
        padding: 3% 0!important;
    }
    .happn{
        .buttons{
            display: flex;
            flex-direction: column!important;
            div{
                margin: 5% 0;
            }
        }
    }
    .dark-section{
        div{
            display: flex;
            flex-direction: column!important;
            video,img{
                align-self: flex-start;
                margin: 4% 0 0 0!important;
                width: 100vw!important;
                width: 100%!important;
            }
            h3{
                margin-bottom: 5%;
            }
        }
    }
    .intro{
        .fred{
            .row{
                display: flex;
                flex-direction: column!important;
                .img-shadow{
                    margin: 5% 0;
                    width: 100%;
                }
                .fred-img{
                    width: 100vw;
                }
            }
        }
    }
    .ecole{
        .row{
            display: flex;
            flex-direction: column!important;
            .img-shadow{
                margin: 5% 0;
                width: 100%;
            }
            .projet-img{
                width: 100vw!important;
            }
        }
        .column.first,.column.second{
            width: 100vw!important;
            width: 100%!important;
        }
        .content1,.content2{
            width: 100%!important;
        }
        .content1{
            margin-bottom: 5%;
        }
    }
    .alternance{
        .row{
            display: flex;
            flex-direction: column!important;
            .img-shadow{
                margin: 5% 0;
                width: 100%;
            }
            .slave-img{
                width: 100vw!important;
            }
        }
    }
    .personnel{
        .row{
            display: flex;
            flex-direction: column!important;
            .img-shadow{
                margin: 5% 0;
                width: 100%;
            }
            .perso-img{
                width: 100vw!important;
            }
        }
    }
    .conclusion{
        h1{
            margin-top: 10%;
        }
    }
}
@media screen and (max-width:480px){
    .navigation{
        padding: 3.5% 0!important;
    }
    h1{
        font-size: 3rem!important;
    }
    h2{
        font-size: 2rem!important;
    }
    .home section h3{
        font-size: 1.5rem!important;
    }
    .container{
        padding: 0 3%!important;
    }
    .img-shadow{
        div{
            transform: translate(2%,-2%)!important;
        }
        &:hover{
            div{
                transform: translate(0,0)!important;
            }
        }
    }
    .dark-section{
        padding: 5% 3%!important;
        img{
            border: none!important;
        }
    }
    footer.footer{
        padding: 5% 3%!important;
        ul{
            width: 100%!important;
        }
    }
    .menu-mobile-expanded{
        a{
            padding: 2% 3%!important;
        }
    }
}