.home{
    header{
        margin-top: 72px;
        height: 100vh;
        video{
            position: absolute;
            z-index: -1;
            left: 0;
            width: 100vw;
            height: 100vh;
            object-fit: cover;    
        }
    }
    h1{
        padding-top: 5%;
        color: $white;
        span{
            font-family: Karmilla-Bold!important;
            font-family: none;
            color: $black;
        }
    }
    h2{
        font-family: Karmilla-Regular;
        font-size: 2.5rem;
        color: $white;
        margin-top: 5%;
    }
    section{
        video{
            position: absolute;
            z-index: -1;
            left: 0;
            width: 100vw;
            height: 100vh;
            object-fit: cover;    
        }
        h3{
            color: $white;
            font-size: 2rem;
            opacity: 0;
            transition: 0.3s;
            width: 100%;
            height: 100%;
            line-height: 800%;
            text-align: center;
        }
        .gradient-one{
            background-image: linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%);
            width: 33%;
            height: 40vh;
            padding: 1%;
            transition: 0.3s;
            @extend %flex-center-center;
            &:hover{
                .card{
                    transition: 0.3s;
                }
                h3{
                    backdrop-filter: blur(5px);
                    opacity: 1;
                }
                background-image: linear-gradient(-45deg, $pink 0%, $blue 48%, $green 90%);
                transition: 0.3s;
            }
        }
        .gradient-two{
            background-image: linear-gradient(45deg, $pink 0%, $blue 52%, $green 90%);
            width: 60%;
            height: 40vh;
            padding: 1%;
            transition: 0.3s;
            @extend %flex-center-center;
            &:hover{
                .card{
                    transition: 0.3s;
                }
                h3{
                    backdrop-filter: blur(5px);
                    opacity: 1;
                }
                background-image: linear-gradient(-45deg, $pink 0%, $blue 48%, $green 90%);
                transition: 0.3s;
            }
        }
        .card{
            background-color: $black;
            width: 100%;
            height: 100%;
            transition: 0.3s;
            @extend %flex-center-center; 

        }
        .intro{
            background-image: url('../../assets/intro.gif');
            background-position: center;
            background-size: cover;
        }
        .ecole{
            background-image: url('../../assets/ecole.gif');
            background-position: center;
            background-size: cover;
        }
        .alternance{
            background-image: url('../../assets/alternance.gif');
            background-position: center;
            background-size: cover;
        }
        .personnel{
            background-image: url('../../assets/personnel.gif');
            background-position: center;
            background-size: cover;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        height: 100vh;
    }
    @media screen and (max-width: 1280px) {
        h3{
            line-height: 800%;
            font-size: 4rem!important;
        }
    }
    @media screen and (max-width:768px){
        h3{
            font-size: 2rem!important;
        }
    }
}
