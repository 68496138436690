.footer{
    a{
        color: $white;
        transition: 0.3s;
        padding: 5px;
        &:hover,&:focus{
            box-shadow: inset 0 calc(-1rem - 10px) 0 0 $white;
            color: $black;
            transition: 0.3s;
        }
    }
    ul{
        width: 50%;
        @extend %flex-row-center;
    }
    li{
        width: fit-content;
    }
    padding-top: 1%;
    padding-bottom: 1%;
    font-family: Karmilla-Bold;
    color: $white;
    background-color: $black;
    border-top: 1px solid $white;
    @extend %flex-row-center;
}
