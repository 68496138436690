.personnel{
    header{
        margin-top: 72px;
        height: 100vh;
        video{
            position: absolute;
            z-index: -1;
            left: 0;
            width: 100vw;
            height: 100vh;
            object-fit: cover;    
        }
    }
    h1{
        padding-top: 5%;
        color: $white;
    }
    h2{
        font-family: Karmilla-Regular;
        font-size: 2rem;
        color: $white;
        margin-top: 5%;
    }
    .button{
        font-family: Karmilla-Regular;
        color: $black;
        margin-top: 3%;
        background-color: $white;
        border: 2px solid $black;
        border-radius: 100px;
        box-shadow: 2px 4px 0 0 $black;
        width: 240px;
        height: 40px;
        transition: 0.3s;
        @extend %flex-center-center;
        &:hover{
            color: $white;
            background-color: $black;
            border: 2px solid $white;
            border-radius: 100px;
            box-shadow: 2px 4px 0 0 $white;
            transition: 0.3s;
        }
    }
    
    .perso{
        h3{
            font-size: 2.5rem;
        }
        h4{
            font-size: 2rem;
            color: $pink;
        }
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin: 5% 0;
            p{
                margin: 2% 0 5% 0;
            }
        }
        .img-shadow{
            background-color: $black;
            margin-right: 5%;
            @extend %flex-center-center;
            &:hover{
                .perso-img{
                    transform: translate(0, 0);
                    transition: 0.3s;
                }
            }
        }
        .perso-img{
            background-image: url('../../assets/blason.png');
            background-color: $white;
            background-size: cover;
            background-position: center;
            width: 35vw;
            aspect-ratio: 1/1;
            transform: translate(5%, -5%);
            border: solid 5px $black;
            transition: 0.3s;
        }
        margin-top: 5%;
    }
    .crea{
        background-color: $black;
        .col-3{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .content{
                width: 33vw;
                height: auto;
                aspect-ratio: 1/1;
                video{
                    width: 100%;
                }
            }
        }
        .col-2{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .content{
                width: 50vw;
                height: auto;
                aspect-ratio: 1/1;
                video{
                    width: 100%;
                }
            }
        }
        .col-1{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .content{
                width: 100vw;
                height: auto;
                aspect-ratio: 16/9;
                video{
                    width: 100%;
                }
            }
        }
        .col-4{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            .content{
                width: 33vw;
                height: 33vw;
                aspect-ratio: 1/1;
                video{
                    width: 100%;
                }
            }
        }
        .pic1{
            background-image: url('../../assets/pic1.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .pic2{
            background-image: url('../../assets/pic2.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .pic3{
            background-image: url('../../assets/pic3.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .pic4{
            background-image: url('../../assets/drunk.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .pic5{
            background-image: url('../../assets/border-drunk.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .pic6{
            background-image: url('../../assets/cristaline.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .pic7{
            background-image: url('../../assets/cacalilou.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .pic8{
            background-image: url('../../assets/nike.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }        
        .pic9{
            background-image: url('../../assets/forest.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .pic10{
            background-image: url('../../assets/hanae.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}