.navigation{
    .container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .menu{
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    button{
        background-color: transparent;
        border: none;
        display: none;
        transition: 0.3s;
        &:hover{
            background-color: $white;
            transition: 0.3s;
            .burger,.close{
                color: $black;
                transition: 0.3s;
            }
        }
    }
    .burger,.close{
        color: $white;
        font-size: 2rem;
        transition: 0.3s;
    }
    .burger-none,.close-none{
        display: none;
    }
    a{
        color: $white;
        font-size: 1.5rem;
        margin-left: 5%;
        font-family: GarciaMarquez;
        transition: 0.3s;
        padding: 5px;
        &:hover{
            box-shadow: inset 0 calc(-1.5rem - 14px) 0 0 $white;
            color: $black;
            transition: 0.3s;
        }
    }
    a.active{
        border-bottom: 4px solid $white;
    }
    a.inactive{
        border-bottom: 4px solid transparent;
        margin: 0;
        &:hover{
            box-shadow: none;
            color: $white;
        }
    }
    .menu-mobile{
        display: flex;
        flex-direction: column;
        display: none!important;
        a{
            margin: 0;
            padding: 2% 10%;
            &:hover{
                box-shadow: inset 0 calc(-10rem) 0 0 $white;
            }
        }
        a.active{
            box-shadow: inset 0 calc(-10rem) 0 0 $white;
            color: $black;
            border-bottom: none;
        }
    }
    .menu-mobile-expanded{
        display: flex;
        flex-direction: column;
        a{
            margin: 0;
            padding: 2% 10%;
            &:hover{
                box-shadow: inset 0 calc(-10rem) 0 0 $white;
            }
        }
        a.active{
            box-shadow: inset 0 calc(-10rem) 0 0 $white;
            color: $black;
            border-bottom: none;
        }
    }
    padding: 1.2% 0;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 1;
    height: fit-content;
    background-color: $black;
}

@media screen and (max-width: 1280px) {

    .navigation{
        padding: 2% 0;
    }
}