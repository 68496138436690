.intro{
    header{
        margin-top: 72px;
        height: 100vh;
        video{
            position: absolute;
            z-index: -1;
            left: 0;
            width: 100vw;
            height: 100vh;
            object-fit: cover;    
        }
    }
    h1{
        padding-top: 5%;
        color: $white;
    }
    h2{
        font-family: Karmilla-Regular;
        font-size: 2rem;
        color: $white;
        margin-top: 5%;
    }
    .button{
        font-family: Karmilla-Regular;
        color: $black;
        margin-top: 3%;
        background-color: $white;
        border: 2px solid $black;
        border-radius: 100px;
        box-shadow: 2px 4px 0 0 $black;
        width: 240px;
        height: 40px;
        transition: 0.3s;
        @extend %flex-center-center;
        &:hover{
            color: $white;
            background-color: $black;
            border: 2px solid $white;
            border-radius: 100px;
            box-shadow: 2px 4px 0 0 $white;
            transition: 0.3s;
        }
    }
    a~.button{
        width: fit-content;
    }
    .fred{
        h3{
            font-size: 2.5rem;
        }
        h4{
            font-size: 2rem;
            color: $pink;
        }
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin: 5% 0;
            p{
                margin: 2% 0 5% 0;
            }
        }
        .img-shadow{
            background-color: $black;
            margin-right: 5%;
            @extend %flex-center-center;
            &:hover{
                .fred-img{
                    transform: translate(0, 0);
                    transition: 0.3s;
                }
            }
        }
        .fred-img{
            background-image: url('../../assets/fred.jpg');
            background-size: cover;
            background-position: center;
            width: 35vw;
            aspect-ratio: 1/1;
            transform: translate(5%, -5%);
            border: solid 5px $black;
            transition: 0.3s;
        }
        margin-top: 5%;
    }
    .question{
        padding: 10% 0;
        margin: 5% 0;
        background-image: url('../../assets/graphics.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        p{
            font-family: GarciaMarquez;
            font-size: 4rem;
            text-align: center;
        }
    }
    .dark-section{
        background-color: $black;
        color: $white;
        padding: 5%;
        div{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 3%;
        }
        h3{
            font-size: 2.5rem;
        }
        img{
            width: 40vw;
            margin-left: 5%;
            border: solid 3px $black;
        }
    }
    .happn{
        margin: 5% 0;
        h3{
            font-size: 2.5rem;
            text-align: center;
        }
        .buttons{
            margin-top: 5%;
            @extend %flex-row-center;
        }
    }
}