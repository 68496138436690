.conclusion{
    section{
        margin: 10% 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    h1{
        font-size: 6rem;
        background-image: url('../../assets/graphics.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
    }
    h2{
        font-family: GarciaMarquez;
        font-size: 3rem;
        color: $pink;
    }
    p{
        margin: 5% 0;
        width: 60%;
    }
    div.comment{
        width: 50px;
        height: 50px;
        &:hover{
            cursor: zoom-in;
        }
    }
}